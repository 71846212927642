<template>
<div class="icon-picker">
    <div  v-if="label" class="ant-col-24 ant-form-item-label"><label :title="label" v-if="label" class="">{{label}}</label></div>
 <div class="icons-palace ant-col-24"><div v-for="(icon,ind) in icons" :key="ind" :title="ind" :class="{'selected':datatosend === ind}" @click="selectThis(ind)"><div class="icon-custom" v-html="icon"></div></div></div>
</div>
</template>

<script>
    import icons from './../icons.json';

    export default {
        name: 'iconSelector',
        props:["label","data"],
        data() {
            return {
        datatosend:"",
        icons: icons}
        },
        methods: {
            selectThis(icon)
            {
                this.datatosend = icon;
                this.$emit("change",this.datatosend);
            }
        },
        mounted() {
            this.datatosend = this.data;
        }
    };
</script>
<style scoped>
.icon-picker
{width:100%;clear:both;display:inline-block;}
     .icon-custom >>> svg
    {width:auto;height:100%;max-width:60px; margin:0 auto;display:block;}
     .selected .icon-custom
     {border-color: #ba7d73; background-color: #fedcbc
     }
    .icon-custom
    {float:left;padding:5px;border:1px solid transparent;width:70px;height:60px; margin:3px;}
     .icon-custom:hover
     {background-color:#1da1f2; cursor:pointer;}
</style>
