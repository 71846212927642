<template>
  <div>
        <div class="rows" :key="index + '_' + keys.length" v-for="(k, index) in keys">

              <div class="form-item" :key="endex + 'index' + index" v-for="(item, endex) in items">
                <iconSelector :data="datatosend[item][index]" class="full-width" :label="item" @change="(e) => handleChange(e,item,index)" v-if="item === 'item.icon'"/>

                    <template v-if="fieldsettings[item].type === 'image'">

                    <image-uploader :key="'imup' + datatosend[item]" :data="datatosend[item][index]" :dat="item" v-if="datatosend[item]" @changed="(e) => handleChange(e,item,index)"/>
                    <image-uploader :key="'imuffp' + datatosend[item]" v-else :dat="item" @changed="(e) => handleChange(e,item,index)"/>
                    </template>
                        <upload :data="datatosend[item][index]" :label="item" :dat="item" v-if="item === 'item.file'" @changed="(e) => handleChange(e,item,index)"/>
                <div v-if="item === 'item.multiplier'">
                    <div class="ant-row ant-form-item" style="margin-bottom: 0px">
                        <div class="ant-col-4 ant-form-item-label"><label class="">Služba</label></div>
                        <div class="ant-col-16 ant-form-item-control-wrapper"><div class="ant-form-item-control"><span class="ant-form-item-children">
                <input class="ant-input" style="width:49%;float:left;margin:.5%;" type="number" @change="(e) => onChildChange(e.target.value,item,index)" placeholder="Počet" v-model="itemcount[index]"/>
<a-select style="width:49%;float:left;margin:.5%;" mode="single" v-model="itemfold[index]"  @change="(e) => onChildChange(e,item,index)"   placeholder="Vyberte si z možností"   >
<a-select-option v-for="op in options" :key="op.value">{{op.label}}</a-select-option>
</a-select>
            </span></div></div>
                    </div></div>
                <div class="textfieldmanage" v-if="fieldsettings[item].type !==  'image' && fieldsettings[item].type !== 'file' && item !== 'item.multiplier' && item !== 'item.file' && item !== 'item.icon'">
                    <div class="ant-row ant-form-item p-2" style="margin-bottom: 0px">
                        <div class="ant-col-20 text-left ant-form-item-label" style="padding-left:5px;line-height:15px"><label class="">{{item}}</label></div>
                        <div class="ant-col-24 ant-form-item-control-wrapper"><div class="ant-form-item-control"><span class="ant-form-item-children">
                     <template v-if="fieldsettings[item].type === 'longtext'">
                    <ckeditor @ready="onReady"  :editor="editor"   @change="(e) => handleChange(e.target.value,item,index)"
                      :placeholder="item" v-model="datatosend[item][index]"  :config="editorConfig"></ckeditor>
                    </template>
                <input class="ant-input" v-else @change="(e) => handleChange(e.target.value,item,index)"  :placeholder="item" v-model="datatosend[item][index]" />
            </span></div></div>
                    </div></div>

          </div>
           <span class="btn-remove"> <a-button type="link" @click="remove(index)"><a-icon type="close" /></a-button></span>
        </div>     <a-form-item >
        <a-button type="dashed" style="float:right" @click="add"><a-icon type="plus" /> Pridaj položku</a-button>
    </a-form-item>

    </div>
</template>

<script>
    import iconSelector from "./iconSelector";
    import Image from "./Image";
    import Upload from "./Upload";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import '@ckeditor/ckeditor5-build-classic/build/translations/sk';
    import UploadAdapter from '../UploadAdapter';

    export default {
        props:["datainput","items","editid","gallery","slug","options","multiplier","fieldsettings"],
        data() {
            return {
                datatosend:{},
                temp:[],
                newdata:[],
                widthsetup:'',
                tempdata:{},
                emptyVals:{},
                editor: ClassicEditor,
                editorConfig: {
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Písané', class: 'ck-heading_heading3' },
                        ]
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', ],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    extraPlugins: [this.uploader],
                    language: 'sk',
                },
                itemfold:[],
                itemcount:[],
                keys:[],
                id: 1
            }},
        components: {iconSelector,'image-uploader' : Image, 'upload' : Upload, },
        name: "multi",

        mounted()
        {   this.items.forEach(this.assignEmpty);
            this.id = 1;
            this.keys = new Array(1);

        this.recalc();

        },
        methods: {
          uploader(editor)
            {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader );
                };
            },
            findMost(dat)
            {
              if(Object.keys(dat).length > this.keys.length)
                this.keys = new Array(Object.keys(dat).length);

             },
             onReady()
            {
var self = this;
                setTimeout(function(){ self.widthsetup = '99.99%'; }, 250);
            },
            recalc()
            {   const self = this;
                 this.emptyVals = this.datatosend;

                if(Object.keys(this.datainput).length !== 0 && this.datainput) {
                  //  console.log(this.datatosend);

 this.datatosend = this.datainput;
                    console.log(this.datatosend);
                    Object.keys(this.datatosend).forEach(function (key) {
                        self.findMost(self.datatosend[key]);
                        console.log(self.keys);
                        if(self.gallery === "true") {
                            self.datatosend[key].forEach(function (e) {
                                self.newdata.push(e);
                            });
                        }
                        if(self.multiplier === "true") {
                            self.datatosend[key].forEach(function (e) {
                                self.itemfold.push(e["item"]);
                            });
                            self.datatosend[key].forEach(function (e) {
                                self.itemcount.push(e["count"]);
                            });
                        }
                    });
                    if(self.gallery === "true")
                        self.datatosend["item.image"] = self.newdata;
                      }
            },
            assignEmpty(item)
            {

             if(!this.datainput)
              {
                if(!this.datatosend[this.slug])
                    this.datatosend[this.slug] = {};
               }
                  this.datatosend[item] = {};

                if(this.slug)
                    this.datatosend[this.slug][item] = {};


            },
            remove(index){
                const self = this;
                Object.keys(this.datatosend).forEach(function (e) {
                    self.datatosend[e].splice(parseInt(index),1);
                    self.itemfold.splice(parseInt(index),1);
                    self.itemcount.splice(parseInt(index),1);
self.findMost(e);
                })


  this.items.forEach(function(x){
delete self.datatosend[x][index];
                 });

 this.items.forEach(function(x){


    if(!Array.isArray(self.datatosend[x]))
       self.datatosend[x]= Object.values(self.datatosend[x]);


self.datatosend[x] = self.datatosend[x].filter(function (el) {
  return el != null;
});

});


                this.recalc()
                this.$emit("deleted",self.datatosend);
            },
            onChildChange(e,item,index){
                this.datatosend[item][index] =  {'item': this.itemfold[index],'count': this.itemcount[index]};
                this.$emit("changed",this.datatosend);
            },
            add(){
                this.keys.push(this.id++);
            },
            handleChange(e, slug, k) {
                if(this.gallery === "true")
                {

                    this.datatosend[slug][k] =  {'image':e};
                }
                else
                    this.datatosend[slug][k] = e;

      this.$emit("changed",this.datatosend);
            },
        }
    }
</script>
